<template>
	<b-col cols="12">
		<b-card>
			<ValidationObserver>
				<form @submit.prevent="saveItem">
					<div class="form-group">
						<label>Tahun *<span class="tooltip_hint" id="hint-1">?</span></label>
						<validation-provider
							name="Year"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								v-model="formProgressive.year"
								placeholder="2019"
								:state="errors.length > 0 ? false:null"
							>
							<small
								v-for="(validation, index) in validations.year"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
						<b-tooltip target="hint-1" triggers="hover">
							Tahun mulai berlaku penghasilan tidak kena pajak tsb
						</b-tooltip>
					</div>
					<div class="form-group">
						<label>Jumlah Progresif *</label>
						<validation-provider
							name="Year"
							rules="required"
							v-slot="{ errors }"
						>
							<b-form-select
								:options="options"
								@change="changeInputs"
								v-model="formProgressive.count"
								:state="errors.length > 0 ? false:null"
							/>
							<small
								v-for="(validation, index) in validations.count"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
					<div 
						class="form-group"
						v-for="(item, index) in inputsItem"
						:key="index"
					>
						<label>Progressive {{ index + 1 }}</label>
						<b-row>
							<b-col
								cols="12"
								md="3"
							>
								<div class="form-group">
									<label>Min *</label>
									<input 
										type="number"
										class="form-control"
										placeholder="1"
										v-model="item.minimal"
									>
								</div>
							</b-col>
							<b-col 
								cols="12"
								md="7"
							>
								<label>Max *</label>
								<input 
									type="number"
									class="form-control"
									placeholder="500000000000"
									v-model="item.maximal"
								>
							</b-col>
							<b-col
								cols="12"
								md="2"
							>
								<label>Percentage(%) *</label>
								<input 
									type="number"
									class="form-control"
									placeholder="5"
									v-model="item.percentage"
								>
							</b-col>
						</b-row>
					</div>
					<div class="d-flex align-items-cennter">
						<button
							type="submit"
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
						>
							Save
						</button>
						&nbsp;
						&nbsp;
						<a
							@click="handleBackButton()"
							variant="secondary"
							class="btn waves-effect waves-float waves-light btn-secondary"
						>
							Cancel
						</a>
					</div>
				</form>
			</ValidationObserver>
		</b-card>
	</b-col>
</template>

<script>
import {
	BCard,
	BRow, 
	BFormGroup, 
	BFormRadio,  
	BCol,
	BTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow, 
		BFormGroup, 
		BFormRadio,  
		BCol,
		BTooltip,
		BFormSelect,
		ValidationProvider,
		ValidationObserver,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			formProgressive: {
				year: '',
				count: '',
			},
			options:[
				{ value: '', text: '--- Pilih Jumlah Progressive ---' },
				{ value: 1, text: '1' },
				{ value: 2, text: '2' },
				{ value: 3, text: '3' },
				{ value: 4, text: '4' },
				{ value: 5, text: '5' },
				{ value: 6, text: '6' },
				{ value: 7, text: '7' },
				{ value: 8, text: '8' },
				{ value: 9, text: '9' },
				{ value: 10, text: '10' },
			],
			inputsItem: [],
			validations: '',
		}
	},

	methods: {
		handleBackButton() {
			history.back()
		},

		changeInputs(option) {
			this.inputsItem = [{}]
			
			for (var i = 1; i < option; ++i) {
				this.inputsItem.push({
					nominal: '',
					maximal: '',
					percentage: ''
				})
			}
		},

		saveItem() {
			this.formProgressive.items = this.inputsItem
			this.$http.post('admin/progressives', this.formProgressive)
			.then(response => {
				successNotification(this, 'Success', 'Progressive sukses dibuat!')
				this.$router.push({ name: 'progressive' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Jumlah Progresif', this.validations.count.join(' '))
					errorNotification(this, 'Tahun', this.validations.year.join(' '))
				}
			})
		}
	}
}
</script>

<style>
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
</style>